import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const QRupiComponent = ({isOpen, onClose, qrCodeValue}) => {
    // const qrCode = `data:image/png;base64,${qrCodeUrl}`;
    if (!isOpen) return null;
    // console.log(qrCodeUrl);
    return ( 
        <>
        <div className="modal-overlay-qr">
        <div className="modal-content-qr">
            <button className="close-button-qr" onClick={onClose}>
            &times;
            </button>
            <div className="qr-code-container">
                <div className="row">
                    <label for="exampleFormControlInput1" class="cashierNewTitel">Please Scan this QR to continue</label>

                </div>
                <img src={qrCodeValue} alt="QR Code" />
            </div>
        </div>
        </div>
        </>     
    );
}
 
export default QRupiComponent;