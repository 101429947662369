import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import { Fragment, } from "react";
import GeneralModalWrapper from "../Modal/GeneralModal/GeneralModalWrapper";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { useAtom } from "jotai";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { nanoid } from "nanoid";
import { preLoader,reRenderCashier } from "../../DataStore/atom";

const FooterComponent = ({
  goToCashier,
  setOpenCashier,
  login,
  openPopup,
  generalModalStatus,
  closePopup,
  generalModalType,
  openGeneralModal,
}) => {
  const [preLoaderCoin, setPreLoader] = useAtom(preLoader);
  const [, setRenderCashier] = useAtom(reRenderCashier);


  return (
    <Fragment>
      <div id="preloader" style={{ display: preLoaderCoin ? "block" : "none" }}>
        <div id="status">&nbsp;</div>
      </div>
      <div
        className="container-fluid footerBg p-3 mt-lg-5"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-8 col-md-8 col-sm-8">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 footerTitel3 text-lg-start text-center">
                  <TranslatesContent contentKey="24-7-support" />
                </div>
                <div
                  className="col-12 col-lg-3 col-md-3 col-sm-6 footerTitel4 text-lg-start text-center"
                  style={{ fontSize: "20px" }}
                >
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    target="_blank"
                    href="https://t.me/+9476856266"
                  >
                    <img src={IMAGE_BUCKET_URL + "telegram.png"} alt="" /> +94
                    768 562 666
                  </a>
                </div>
                <div
                  className="col-12 col-lg-3 col-md-3 col-sm-6 footerTitel4 text-lg-start text-center"
                  style={{ fontSize: "20px" }}
                >
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    target="_blank"
                    href="https://wa.me/+94768562666"
                  >
                    <img src={IMAGE_BUCKET_URL + "whatsapp.png"} alt="" /> +94
                    768 562 666
                  </a>
                </div>
              </div>
              <div className="row mt-4 footerBgInner">
                <div className="col-12 col-lg-8 col-md-12">
                  <div className="footerTitel1 text-lg-start text-center">
                    <TranslatesContent contentKey="double-your-money" />
                  </div>
                  <div className="footerTitel2 text-lg-start text-center">
                    <TranslatesContent contentKey="24-7-support" />
                  </div>
                </div>
                <div className="col-12 col-lg-3 col-md-12 d-flex align-items-center justify-content-center">
                  {login ? (
                    <Link to="/cashier">
                    <button
                    
                      type="button"
                      onClick={(e)=>{
                        e.preventDefault();
                        setRenderCashier(nanoid());
                        goToCashier();
                      }}
                      className="btn btn-warning align-middle joinTopbutton"
                    >
                      <TranslatesContent contentKey="deposit" />
                    </button>
                    </Link>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        openPopup("login");
                        setOpenCashier(true);
                      }}
                      className="btn btn-warning align-middle joinTopbutton"
                    >
                      <TranslatesContent contentKey="deposit" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-4 mt-3">
              <div className="row">
                <div className="col-6 col-lg-6 col-md-6">
                  <ul className="nav d-flex text-left text-lg-start text-md-start flex-column footerOutBoxLtext">
                    <li className="mb-3">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openGeneralModal("contact");
                        }}
                      >
                        <TranslatesContent contentKey="contact-us" />
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openGeneralModal("agreement");
                        }}
                      >
                        <TranslatesContent contentKey="user-agreement" />
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openGeneralModal("faq");
                        }}
                      >
                        <TranslatesContent contentKey="information-faq" />
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openGeneralModal("gambling");
                        }}
                      >
                        <TranslatesContent contentKey="responsible-gambling" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-lg-6 col-md-6">
                  <ul className="nav d-flex text-left text-lg-start text-md-start flex-column footerOutBoxLtext">
                    <li className="mb-3">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openGeneralModal("rules");
                        }}
                      >
                        <TranslatesContent contentKey="game-rules" />
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openGeneralModal("guide");
                        }}
                      >
                        <TranslatesContent contentKey="game-guide" />
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openGeneralModal("bonus");
                        }}
                      >
                        <TranslatesContent contentKey="bonus-policy" />
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openGeneralModal("customerVerification");
                        }}
                      >
                        Customer Verification
                        {/* <TranslatesContent contentKey="bonus-policy" /> */}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-12 col-md-12">
                  <div className="footerTitel5 text-lg-end text-center">
                    <TranslatesContent contentKey="playing-responsbly-within-limits" />
                  </div>
                  <div className="footerTitel3 text-lg-end text-center">
                    <img
                      src={IMAGE_BUCKET_URL + "payment-icon-new.png"}
                      className="img-fluid "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid footerBg">
          <div className="row p-3">
            <div className="col-12 col-lg-12 footerOutBoxRtext  text-center">
              <TranslatesContent contentKey="powered-by" /> <a href="">iTone</a>
            </div>
          </div>
        </div>
      </div>

      {/* // general popup */}
      <GeneralModalWrapper
        type={generalModalType}
        status={generalModalStatus}
        closePopup={closePopup}
      />
    </Fragment>
  );
};

export default FooterComponent;
