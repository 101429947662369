import axios from "axios";
import mergeImages from 'merge-images';
import { Fragment, useState } from "react";
import {
  DEPOSIT_SLIP_URL,
  IMAGE_BUCKET_URL,
  IMGBUCKETURL,
  DEOSITBONUSURL
} from "../../DataStore/ApiConstant";
import ImportantNotice from "./ImportantNotice";
// import WhatsappButton from "../Cashier/whatsappButton";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import BonusOffer from "../TransactionCommon/BonusOffer";
import CashierInput from "../../Components/TransactionCommon/CashierInput";
import { TOAST_CONFIG } from "../../constants";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { getFormData, validateNumberInput } from "../../utils";
import { useBankPaymentSlips } from "../../services/cashier";
import { useAtom } from "jotai";
import { bonusStore } from "../../DataStore/atom";

let validator = {
  // amount: yup
  //   .number()
  //   .required("Please provide amount.")
  //   .min(1, "Must be greater than 0")
  //   .typeError("Amount must be a number"),
};

const Bankayment = () => {
  const schema = yup.object().shape(validator);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  // const [currentSte , setCurrentStep] = useState(1);\
  const [BonusStore, setBonusStore] = useAtom(bonusStore);

  const uploadImgUrl = `${IMAGE_BUCKET_URL}upload.png`;
  const [depositBonus, setDepositBonus] = useState(true);
  const [amount, setAmount] = useState(0);

  const [rawAmount, setRawAmount] = useState(0);
  const [imagesrc, setImagesrc] = useState(uploadImgUrl);
  const [imageFile, setImgFile] = useState("");
  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [paymentErr, setPaymentErr] = useState(false);
  const [formattedAmount, setFormattedAmount] = useState("");

  const [selectedOption, setSelectedOption] = useState(1);

  const bankSlips = useBankPaymentSlips()


  const bonusOfferSwitch = async (status) => {
    setSelectedOption((prevOption) => (prevOption === status ? "" : status));
    setBonusStore(status);
    if (status) {
      axios
        .get(DEOSITBONUSURL)
        .then((resp) => {
          setDepositBonus(resp);
        })
        .catch((err) => { });
    } else {
      setDepositBonus(null);
    }
  };
  // const processUploadFile = (e) => {
  //   setImgFile(e.target.files[0]);
  //   const reader = new FileReader();
  //   if (e.target.files && e.target.files.length) {
  //     const [file] = e.target.files;
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       let imageSrc = reader.result;
  //       setImagesrc(imageSrc);
  //     };
  //   }
  // };
  const processUploadFile = (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
      if (validImageTypes.includes(file.type)) {
        setImgFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let imageSrc = reader.result;
          
          setImagesrc(imageSrc);
          
        };
      } else {
        setErrmsg('Please upload a valid image file (jpg, png, gif, bmp, or webp).');
        setLoader(true);
        setImgFile("");
        setTimeout(() => {
          setErrmsg("");
          setLoader(false);
        }, 3000);

        // Reset the input field
        fileInput.value = '';
        return;
      }
    }
  };


  const ValidateDepositAmount = (reAmount) => {

    const numericValue = reAmount.replace(/[^0-9.]/g, "");

    setRawAmount(numericValue)
    // setAmount(reAmount);
    setFormattedAmount(numericValue ? formatCurrency(numericValue) : "");
    if (reAmount != "" && (reAmount < 10000)) {
      setSuccessMsg(false);
      return setErrmsg("Please enter an amount greater than the minimum limit.");
    }
    // setAmount(reAmount)
    setErrmsg(false);
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("en-US", {
      // style: "currency",
      currency: "USD",
    }).format(value);

    return formattedValue;
  };


  //new implementation image merge

  const [images, setImages] = useState([]);
  const [collage, setCollage] = useState(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const imageUrls = [...images];
    const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp"];

    for (let i = 0; i < files.length; i++) {
      if (validImageTypes.includes(files[i].type)) {
      imageUrls.push(URL.createObjectURL(files[i]));
    } else {
      setErrmsg("Please upload a valid image file (jpg, png, gif, bmp, or webp).");
      setTimeout(() => setErrmsg(""), 3000); 
      return; 
    }
  }

    setImages(imageUrls);
  };

  // Remove an image from the preview list
  const removeImage = (index) => {
    const updatedImages = images.filter((_, idx) => idx !== index);
    setImages(updatedImages);
  };

  //fixed square size
  const resizeImage = (src, width, height) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Prevent CORS issues
      img.src = src;
      img.onload = () => {


        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');

        // Draw resized image on canvas
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); 
      };
    });
    
  };
  

  // Generating collage
  const generateCollage = () => {
    return new Promise(async (resolve, reject) => {
      if (images.length === 0) {
        alert('Please select at least one image.');
        reject("No images selected");
        return;
      }

      const size = 720; 
      const columns = Math.ceil(Math.sqrt(images.length)); 
      const rows = Math.ceil(images.length / columns); 

      try {
        
        const resizedImages = await Promise.all(
          images.map((src) => resizeImage(src, size, size))
        );

        mergeImages(
          resizedImages.map((src, index) => ({
            src,
            x: (index % columns) * size, 
            y: Math.floor(index / columns) * size, 
          })),
          { width: columns * size, height: rows * size } 
        ).then((b64) => {
          setImagesrc(b64);
          resolve(b64); // Resolve with generated image
        }).catch((err) => {
          console.error('Error merging images:', err);
          reject(err);
        });
      } catch (err) {
        console.error("Error resizing images:", err);
        reject(err);
      }
    });
  };




  let submitHandler = async (bankPaymentObj) => {
    try {
      setLoader(true); // Show loader
  
      let collage = await generateCollage(); // Wait for collage to generate
  
      if (!collage) {
        setErrmsg("Please enter an Image...");
        setTimeout(() => setErrmsg(""), 3000);
        setLoader(false); // Hide loader on error
        return;
      }
  
      if (!bankPaymentObj.amount || bankPaymentObj.amount == 0) {
        setErrmsg("Please enter a valid amount");
        setTimeout(() => setErrmsg(""), 3000);
        setLoader(false); // Hide loader on error
        return;
      }
  
      let bonusAmt = depositBonus == null ? 0 : 1;
      let currentDate = new Date();
      let formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`; // MM-DD-YYYY format
      let imageName = `proof_of_payment_${formattedDate}.png`;
  
      let obj = {
        amount: rawAmount,
        imageName: imageName,
        data: collage, // Use updated image data
        bonus: bonusAmt
      };
  
      console.log("body", obj);
  
      axios
        .post(DEPOSIT_SLIP_URL, getFormData(obj), {
          headers: { "Content-Type": "application/json" },
        })
        .then((resp) => {
          if (resp.data.status === true) {
            setSuccessMsg(resp.data.message);
            setTimeout(() => setSuccessMsg(""), 5000);
            reset();
            setImgFile("");
            setImagesrc(uploadImgUrl);
          } else {
            setErrmsg(resp.data.message);
            setTimeout(() => setErrmsg(""), 5000);
          }
          setLoader(false); // Hide loader after response
        })
        .catch((err) => {
          console.error("API Error:", err);
          setLoader(false); // Hide loader on error
        });
    } catch (err) {
      console.error("Error in submission:", err);
      setLoader(false); // Hide loader on error
    }
  };
  

  return (
    <div
      //for remove upi payment
      // className="tab-pane fade"
      // id="v-pills-profile"
      // role="tabpanel"
      // aria-labelledby="v-pills-profile-tab"
      className="tab-pane fade show active "
      id="v-pills-home"
      role="tabpanel"
      aria-labelledby="v-pills-home-tab"
    >
      <div className="row">
        <>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="row d-flex justify-content-center my-5">
              <div className="col-md-4 mb-3">
                <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
                  <TranslatesContent contentKey="deposit-amount" />
                </label>
                <div className="input-group">
                  <span
                    className="input-group-text"
                    style={{
                      border: "1px solid #2f2f2f",
                      fontWeight: 600,
                      backgroundColor: "#c7c7c7"
                    }}
                  >
                    LKR
                  </span>
                  <input
                    placeholder="0"
                    // onInput={(e) => validateNumberInput(e.target)}
                    type="text"
                    style={{ textAlign: "right" }}
                    className={`form-control ${_.isEmpty(errors)
                      ? ""
                      : errors?.amount
                        ? "is-invalid"
                        : "is-valid"
                      }`}
                    {...register("amount")}
                    onInput={(e) => {
                      ValidateDepositAmount(e.target.value);
                    }}
                    value={formattedAmount}
                  // placeholder=""
                  />
                  <div className="col-12">
                    <div className="form-text text-center">
                      minimum limit : LKR
                      10,000
                    </div>
                  </div>
                  {/* <div class="form-text">Min/Max Limit: INR 500 / INR 5000</div> */}
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="mb-3">
                  <label htmlFor="formFile" className="cashierNewTitel">
                    <TranslatesContent contentKey="proof-of-payment" />
                  </label>
                  {/* <input
                    type="file"
                    className="form-control"
                    data-val="Upload Photo"
                    onChange={(e) => processUploadFile(e)}
                  /> */}
                  <input
                    type="file"
                    className="form-control"
                    data-val="Upload Photo"
                    multiple
                    accept="image/*"
                    onChange={handleFileChange}
                  />

                  {images.length > 0 && (
                    <div>
                      <div className="image-preview">
                        {images.map((src, index) => (
                          <div key={index} className="image-container">
                            <img src={src} alt="preview" />
                            <button onClick={() => removeImage(index)} className="remove-btn">
                              X
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-md-3 mb-3">
                  <div className="row">
                    <div className="col-12" style={{ textAlign: "center" }}>
                      <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
                        Bonus Activation
                      </label>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="px-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            style={{padding:"0"}}
                            type="radio"
                            defaultValue=""
                            id="flexCheckDefault"
                            checked={selectedOption === 1}
                            onChange={() => bonusOfferSwitch(1)}
                          />
                          <label
                            className="form-check-label mt-1 ms-2"
                            htmlFor="flexCheckDefault"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                      <div className="px-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            style={{ padding: "0" }}
                            type="radio"
                            defaultValue=""
                            id="flexCheckDefault"
                            checked={selectedOption === 0}
                            onChange={() => bonusOfferSwitch(0)}
                          />
                          <label
                            className="form-check-label mt-1 ms-2"
                            htmlFor="flexCheckChecked"
                          >
                            No
                          </label>

                          {collage && (
                            <div>
                              <h3>Generated Collage</h3>
                              <img src={collage} alt="Collage" width="400" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
              Bonus
            </label> */}
                {/* <div className="fform-check form-switch form-switch-lg">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                // defaultChecked="true"
                checked={BonusStore}
                    onChange={(e) => {
                      bonusOfferSwitch(e.target.checked);
                    }}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
                style={{ marginLeft: 15, marginTop: 10 }}
              >
                Add Bonus Offer
              </label>
            </div> */}
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-md-6 mb-3">
                  {errors?.amount?.message ? (
                    <div className="invalid-feedback">
                      {errors?.amount?.message}
                    </div>
                  ) : null}
                  {successMsg && (
                    <div
                      style={{ marginTop: "30px" }}
                      className="alert alert-success"
                    >
                      {successMsg}
                    </div>
                    // <div className="alert alert-success">{successMsg}</div>
                  )}
                  {errmsg && (
                    <div
                      style={{ marginTop: "30px" }}
                      className="alert alert-danger"
                    >
                      {errmsg}
                    </div>
                  )}
                  {paymentErr && (
                    <div
                      style={{ marginTop: "30px" }}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {paymentErr}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center my-5">
              <div className="col-md-4 mb-3">
                  <button
                  disabled={loader}
                  type="submit"
                  className="btn btn-primary btn-lg w-100"
                >
                  {loader ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-2" role="status"></span>
                      Uploading...
                    </>
                  ) : (
                    "CONFIRM"
                  )}
                </button>

                {/* <button type="button" className="btn btn-primary btn-lg w-100">
              CONFIRM
            </button> */}
              </div>
            </div>
          </form>
        </>
        {/* 
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="col-12 col-lg-10 col-md-8 col-sm-8 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 offset-0">
                    <div className="row">
                      <div className="col-12 col-lg-7 col-md-7 col-sm-12 col-xl-7 mt-4">
                        <label
                          for="exampleFormControlInput1"
                          className="cashierNewTitel"
                        >
                          <TranslatesContent contentKey="deposit-amount" />
                        </label> */}
        {/* <input
                          onInput={(e) => validateNumberInput(e.target)}
                          type="number"
                          className={`form-control ${
                            _.isEmpty(errors)
                              ? ""
                              : errors?.amount
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          {...register("amount")}
                          onChange={(e) => {
                            ValidateDepositAmount(e.target.value);
                          }}
                        /> */}
        {/* <CashierInput
                          onSubmit={setAmount}
                          value={amount}
                          type="number"
                          className={`form-control ${
                            _.isEmpty(errors)
                              ? ""
                              : errors?.amount
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          {...register("amount")}
                        /> */}
        {/* {errors?.amount?.message ? (
                          <div className="invalid-feedback">
                            {errors?.amount?.message}
                          </div>
                        ) : null} */}

        {/* <div
                          id="emailHelp"
                          className="form-text text-start"
                          style={{ color: "#FFFFFF", fontSize: "16px"}}
                        >
                          <TranslatesContent contentKey="min-max-limit" /> : INR
                          500 / INR 100,000
                        </div>
                      </div>

                      <div className="col-12 col-lg-8 col-md-8col-sm-8 col-xl-8 mt-4">
                        <label
                          for="exampleFormControlInput1"
                          className="cashierNewTitel"
                        >
                          <TranslatesContent contentKey="proof-of-payment" />
                        </label>

                        <div className="row">
                          <div className="col-12"></div>
                        </div>
                        <div className="col-12 text-center imgUp">
                          <div
                            className="imagePreview"
                            style={{ backgroundImage: `url(${imagesrc})` }}
                          ></div>
                          <label className="btn newcashierButton mt-2">
                            Upload Slip
                            <input
                              type="file"
                              className="uploadFile img"
                              data-val="Upload Photo"
                              onChange={(e) => processUploadFile(e)}
                              style={{
                                width: "1px",
                                height: "0px",
                                overflow: "hidden",
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
        {/* <BonusOffer name={"deposit-bonus"} /> */}
        {/* <div className="col-lg-6" >
                <div className="row lg-4" > */}
        {/* {
                    !bankSlips.isLoading && bankSlips.data.map((item,i) => {
                      return (
                            <>
                            <div className="col-6 mt-4" key={item.id}>
                              <a href="" data-bs-toggle="modal" data-bs-target={`#proofofpaymentimage-${i}`}>
                              <img src={"https://storage.googleapis.com/itonecdn/imgWeb/new-web/"+item.image} className="img-fluid" alt="" />
                              </a>
                            </div>
                            <div className="modal fade" id={`proofofpaymentimage-${i}`} tabIndex={-1} aria-labelledby={`proofofpaymentimageLabel-${i}`} aria-hidden="true" style={{backgroundColor: "rgba(0, 0, 0, 0.63)"}}>
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body">
                                      <siv className="row">
                                        <div className="col-12">
                                          {" "}
                                          <img src={"https://storage.googleapis.com/itonecdn/imgWeb/new-web/"+item.image} className="img-fluid" alt="" />
                                        </div>
                                      </siv>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                            )
                          })
                  } */}
        {/* </div>
              </div>
              <div className="col-lg-6  offset-xl-1 offset-lg-2 offset-md-2 offset-sm-0 offset-0 mt-2">
                <div className="row">
                  <div className="col-12">
                    <div className="row mt-4"> */}

        {/* {successMsg && (
                          <div
                            style={{ marginTop: "30px" }}
                            className="alert alert-success"
                          >
                            {successMsg}
                          </div>
                          // <div className="alert alert-success">{successMsg}</div>
                        )}
                        {errmsg && (
                          <div
                            style={{ marginTop: "30px" }}
                            className="alert alert-danger"
                          >
                            {errmsg}
                          </div>
                        )}
                        {paymentErr && (
                          <div
                            style={{ marginTop: "30px" }}
                            className="alert alert-danger"
                            role="alert"
                          >
                            {paymentErr}
                          </div>
                        )}
                      <div className="col-6 col-lg-4 col-md-4 col-sm-4">
                        <button
                          disabled={loader}
                          type="submit"
                          className="newcashierButton"
                        >
                          {loader ? (
                            "Saving"
                          ) : (
                            <TranslatesContent contentKey="submit" />
                          )} */}
        {/* </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form> */}
        {/* whatsapp button */}
        {/* <WhatsappButton /> */}
      </div>

    </div>

  );
};

export default Bankayment;
