import axios from "axios";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { WITHDROW_URL } from "../../DataStore/ApiConstant";
import { TOAST_CONFIG } from "../../constants";
import BankAccountForm from "../Cashier/BankAccountForm";
// import WhatsappButton from "../Cashier/whatsappButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { getFormData, calculateBonus, calculateTurnover, validateNumberInput } from "../../utils";
import BankAccountDropDown from "./atomic/BankAccountDropDown";
import { toast } from "react-toastify";
import { useWithdrawBonus } from "../../services/cashier";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import RedepositOffer from "../TransactionCommon/RedepositOffer";
import { UserData } from "../../DataStore";
import BankAccountsTable from "../WIthdrow/atomic/BankAccountsTable";

let validator = {
  // amount: yup,
  // .number()
  // .required("Please provide amount.")
  // .min(0, "Must be greater than 0")
  // .typeError("Amount must be a number"),
};
// const witdrowMethods = useWithdrawNethod();


const Withdrowal = ({ withdrowData, witdrowMethods, users, getUserData, countryList }) => {

  //remove deposit to membership id
  // let wit = witdrowMethods.data && witdrowMethods.data.filter(item => item.id !== 3)
  // console.log(wit);
  
// console.log(countryList);
  const bonus = useWithdrawBonus();
  const userSession = users?.data?.session;
  const schema = yup.object().shape(validator);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [withdrowMethod, setWithdrowMethod] = useState(0);
  const [checkBonus, setcheckBonus] = useState({});
  const [selectedBank, setSelectedBank] = useState();
  const [addToFriend, setAdToFriend] = useState(userSession?.mid);
  const [checkSubmit, setCheckSubmit] = useState();
  const [redepositBonus, setRedepositBonu] = useState(null);
  const [amount, setAmount] = useState(0);
  const [load, setLoad] = useState(false);
  const [refetch, setRefetch] = useState("12");
  const [expander, setExpander] = useState(false);

  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [errmsgRedepo, seterrmsgRedepo] = useState("");
  const [successMsgRedepo, setSuccessMsgRedepo] = useState("");

  const [reBonus, setReBonus] = useState(0);
  const [reTurn, setReTurn] = useState(0);

  const [withdrawalTurnover, setWithdrawalTurnover] = useState(0);
  const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);
  const [eftTurnover, setEftTurnover] = useState(0);

  const [subButton, setsubButton] = useState("");

  const [hideWithdraw, setHideWithdraw] = useState(false);
  const [rawAmount, setRawAmount] = useState(0);
  const [formattedAmount, setFormattedAmount] = useState("");
  
  useEffect(() => {
    setWithdrowMethod(0);
    return setWithdrowMethod(0);
  }, []);
  
  let submitHandler = (rest) => {
    // Initialize default values for the object
    // setSelectedBank(null)
    // setAdToFriend("")
    // setAmount(0)
    let obj = {
      withdraw_method: "",
      // bank_account: "",
      withdrawChk: "off",
      redepositChk: "off",
      redeposit: 0,
      amount: 0,
    };
  
    setLoad(true);
  
    // If there's an error message, stop the process
    if (errmsg) {
      setLoad(false);
      return;
    }
  
    // Destructure and handle additional properties from `rest`
    // let { addToFriend, ...restValues } = rest;
  
    let redepositCheck = parseInt(redepositBonus) > 0 ? "on" : "off";
    let witdrowCheck = parseInt(amount) > 0 ? "on" : "off";
  
    // Update `obj` with `withdrawMethod` if provided
    obj = withdrowMethod ? { ...obj, withdraw_method: withdrowMethod } : obj;
  
    // Validate selected bank
    if (!selectedBank && withdrowMethod == 1) {
      setErrmsg("Please select a bank account");
      setTimeout(() => {
        setErrmsg("");
        setLoad(false);
      }, 3000);
      return;
    }
  
    // Add selected bank or default value to `obj`
    obj = { ...obj, bank_account: selectedBank || "" };
  
    // Add other values to `obj`
    obj = {
      ...obj,
      addToFriend:addToFriend,
      withdrawChk: witdrowCheck,
      redepositChk: redepositCheck,
      redeposit: redepositBonus == null ? 0 : redepositBonus,
      amount: amount == null ? 0 : amount,
      // ...restValues, // Merge remaining values from `rest`
    };
  
    setCheckSubmit(obj);
  
    // Perform the API request
    axios
      .post(WITHDROW_URL, getFormData(obj))
      .then((resp) => {
        if (resp.data.resp === "done") {
          subButton === "withdraw"
            ? setSuccessMsg("Withdraw Successful")
            : setSuccessMsgRedepo("Withdraw Successful");
  
          setTimeout(() => {
            setSuccessMsg("");
            setSuccessMsgRedepo("");
          }, 3000);
          setAmount(0)
          setLoad(false);
          reset();
        } else {
          subButton === "withdraw"
            ? setErrmsg(resp?.data?.resp || "Failed")
            : seterrmsgRedepo(resp?.data?.resp || "Failed");
  
          setTimeout(() => {
            setErrmsg("");
            seterrmsgRedepo("");
          }, 3000);
  
          setLoad(false);
        }
      })
      .catch((err) => {
        subButton === "withdraw" ? setErrmsg(err) : seterrmsgRedepo(err);
        setLoad(false);
      });
  };
  

  const handleFocus = (event) => {
    event.target.select();
  };

  let setRedepositBonus = (reAmount) => {
    setSuccessMsg("");
    seterrmsgRedepo("");
    setRedepositBonu(reAmount);
    let bonusRet = calculateBonus(
      reAmount,
      bonus?.data[2]["maxbonus"] || 0,
      bonus?.data[2]["bonusprecent"] || 0
    );
    setReBonus(bonusRet);
    let turnRet = calculateTurnover(
      reAmount,
      bonus?.data[2]["maxbonus"] || 0,
      bonus?.data[2]["turnover"] || 0,
      bonus?.data[2]["bonusprecent"] || 0
    );
    setReTurn(turnRet);
  };
  const formatCurrency = (value) => {
    // Format the raw value as currency
    const formattedValue = new Intl.NumberFormat("en-US", {
      // style: "currency",
      currency: "USD", // Set your desired currency here
    }).format(value);
  
    return formattedValue;
  };
  let calculateBonusesWithdraw = (reAmount) => {
    const numericValue = reAmount.replace(/[^0-9.]/g, "");
    setRawAmount(numericValue)
    setAmount(numericValue);
    setFormattedAmount(numericValue ? formatCurrency(numericValue) : "");
    setSuccessMsg("");
    // if (reAmount != "" && (reAmount < 500 || reAmount > 10000)) {
    if (reAmount != "" && (bonus?.data[1]["withdrawable"])<parseInt(reAmount) && 0<parseInt(reAmount)) {
      setErrmsg("Insufficient balance !");
      setLoad(true)
    } else {
      setLoad(false)
      setErrmsg(false);
    }
    let bonusRet = calculateBonus(
      reAmount,
      bonus?.data[2]["maxbonus"] || 0,
      bonus?.data[2]["bonusprecent"] || 0
    );
    setEftTurnover(bonusRet);
    let turnRet = calculateTurnover(
      reAmount,
      bonus?.data[2]["maxbonus"] || 0,
      bonus?.data[2]["turnover"] || 0,
      bonus?.data[2]["bonusprecent"] || 0
    );
    setWithdrawalTurnover(turnRet);

  };
  useEffect(() => {
    setValue("amount", 0);
    getUserData();

    // setValue("re", 0);
  }, []);

  // useEffect(() => {
  //   if (successMsg) {
  //     getUserData(); 
  //   }
  // }, [successMsg, getUserData]); 

  return (
    <div class="row p-4">
      <div class="col-12 col-lg-10 col-md-8 col-sm-10 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-1 offset-0">
        <form onSubmit={handleSubmit(submitHandler)} class="row">
        <div class="col-lg-8 col-xl-8 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-1 offset-0">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="col-12">
                      <label
                        for="exampleFormControlInput1"
                        class="cashierNewTitel"
                      >
                        <TranslatesContent contentKey="withdraw-amount" />
                      </label>
                    </div>
                    <div class="col-12">
                      <input
                        // onInput={(e) => validateNumberInput(e.target)}
                        type="text"
                        style={{textAlign:"right"}}
                        onChange={(e) => {
                          calculateBonusesWithdraw(e.target.value);
                        }}
                        class={`form-control ${
                          _.isEmpty(errors)
                            ? ""
                            : errors?.amount
                            ? "is-invalid"
                            : "is-valid"
                        }`}
                        onFocus={handleFocus}
                        placeholder="0"
                        value={formattedAmount}
                        // {...register("amount")}
                      />
                      {errors?.amount?.message ? (
                        <div className="invalid-feedback">
                          {errors?.amount?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                    <div class="col-12">
                      <label
                        for="exampleFormControlInput1"
                        class="cashierNewTitel"
                      >
                        <TranslatesContent contentKey="withdraw-method" />
                      </label>
                    </div>
                    <div class="col-12">
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        onChange={(e) => {
                          e.target.value != 0 ? setWithdrowMethod(e.target.value) : setWithdrowMethod(0);
                        }}
                      >
                        <option value="0">Select Method</option>
                        {witdrowMethods.data &&
                          witdrowMethods.data.map((method) => (
                            <option value={method.id}>{method.method}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  {withdrowMethod != 0 ? (
                    <div class="col-12 box hand" style={{display: "block"}}>
                    <div class="row">
                      <div class="col-12">
                      <div class="row">
                          <div class="col-12 col-lg-5 bonusResultLeft">
                            <TranslatesContent contentKey="Maximum-Withdawal-Amount" />
                            :
                          </div>
                          <div class="col-12 col-lg-7 bonusResultRight">
                            {new Intl.NumberFormat().format(!bonus.isLoading &&  bonus?.data?.length > 1 && bonus?.data[1]["withdrawable"])}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-lg-5 bonusResultLeft">
                            {/* <TranslatesContent contentKey="withdrawal-turnover" /> */}
                            Required Points
                            :
                          </div>
                          <div class="col-12 col-lg-7 bonusResultRight">
                          {bonus.isLoading==false ? bonus?.data?.length > 0 && bonus?.data[0]?.giventurnover:"0"}
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-12 col-lg-5 bonusResultLeft">
                            {/* <TranslatesContent contentKey="archived-eft-turnover" /> */}
                            Archived Points
                            :
                          </div>
                          <div class="col-12 col-lg-7 bonusResultRight">
                            {/* {eftTurnover} */}

                            {bonus.isLoading==false ? bonus?.data?.length > 0 && bonus?.data[0].achivedturnover:"0 "}
                          </div>
                        </div>
                        {/* {withdrowMethod == "3" ? null : (
                              <Bonus checkBonus={setBonusState} />
                            )} */}
                      </div>
                    </div>
                  </div>
                  ):null}
                  {withdrowMethod == 2 && (
                    <div class="col-12 box directbank">
                      {/* {withdrowMethod} */}
                      <div class="row">
                        <BankAccountDropDown
                          sync={refetch}
                          bankSet={(val) => setSelectedBank(val)}
                        />
                        <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label
                            for="exampleFormControlInput1"
                            class="cashierNewTitel"
                          >
                            &nbsp;
                          </label>
                          <a
                            onClick={() => setHideWithdraw(!hideWithdraw)}
                            data-bs-toggle="collapse"
                            href="#collapseaddnewbank"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseaddnewbank"
                            id="bankMainCollapse"
                          >
                            <div class="addBankButton">
                              {" "}
                              +
                              <TranslatesContent contentKey="add-new-bank-account" />
                            </div>
                          </a>
                        </div>

                        <div class="row mt-4 collapse" id="collapseaddnewbank">
                          <div class="col-12">
                            <label
                              for="exampleFormControlInput1"
                              class="cashierNewTitel"
                            >
                              <TranslatesContent contentKey="add-new-bank-account" />
                            </label>
                          </div>
                          <BankAccountForm countryList={countryList}setHideWithdraw={setHideWithdraw}/>
                        </div>
                      </div>
                    </div>
                  )}

                  {withdrowMethod == 3 && (
                    <div class="col-12 box membership">
                      <div class="row">
                        <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                          <label
                            for="exampleFormControlInput1"
                            class="cashierNewTitel"
                          >
                            <TranslatesContent contentKey="membership-id" />
                          </label>
                          <input
                            disabled
                            class="form-control"
                            type="text"
                            aria-label="readonly input example"
                            readonly
                            value={userSession?.mid}
                            onClick={() => {
                              // setHideWithdraw(true);
                             }}
                          />
                        </div>
                        <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                          <label
                            for="exampleFormControlInput1"
                            class="cashierNewTitel"
                          >
                            <TranslatesContent contentKey="add-to-friends-id" />
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            onChange={(e) => {
                              setAdToFriend(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {successMsg && (
              <div
                style={{ marginTop: "30px" }}
                className="alert alert-success"
              >
                {successMsg}
              </div>
              // <div className="alert alert-success">{successMsg}</div>
            )}

            {/* bank payment error */}
            {errmsg && (
              <div style={{ marginTop: "30px" }} className="alert alert-danger">
                {errmsg}
              </div>
              // <div class="alert alert-danger" role="alert">
              //   {errmsg}
              // </div>
            )}

            {/* {(withdrowMethod == 3) && ( */}
            {(hideWithdraw != true) & (withdrowMethod != 0) ? (
              <div class="row d-flex justify-content-center my-5">
                <div className="col-md-4 mb-3">
                {/* {withdrowMethod} */}
                <button
                  type="submit"
                  class="btn btn-primary btn-lg w-100"
                  style={load?{cursor: "not-allowed"}:null}
                  disabled={load}
                  onClick={() => setsubButton("withdraw")}
                >
                  {/* {load ? (
                    "Processing"
                  ) : (
                    <TranslatesContent contentKey="withdraw-cashier" />
                  )} */}
                    <TranslatesContent contentKey="withdraw-cashier" />
                  
                </button>
                </div>
              </div>
            ) : null}
             {withdrowMethod == 2 && (
              <BankAccountsTable/>
            )}


          </div>
          {/* <div class="col-lg-6 col-xl-6 mt-2"> */}
            {/* <div class="row"> */}
              {/* <RedepositOffer /> */}

              {/* <div class="col-12 col-lg-8 col-md-8 col-sm-8 col-xl-8 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-2 offset-0 mt-4">
                <div class="row">
                  <div class="col-12">
                    <label
                      for="exampleFormControlInput1"
                      class="cashierNewTitel"
                    >
                      <TranslatesContent contentKey="redeposit-amount" />
                    </label>
                  </div>
                  <div class="col-12 mt-4">
                    <input
                      type="number"
                      value={redepositBonus}
                      onChange={(e) => setRedepositBonus(e.target.value)}
                      class={`form-control ${
                        _.isEmpty(errors)
                          ? ""
                          : errors?.redepositAmount
                          ? "is-invalid"
                          : "is-valid"
                      }`}
                      onFocus={handleFocus}
                      placeholder=""
                    />
                    {errors?.redepositAmount?.message ? (
                      <div className="invalid-feedback">
                        {errors?.redepositAmount?.message}
                      </div>
                    ) : null}
                  </div>
                  <div class="col-12">
                    <br />
                    <div class="row">
                      <div class="col-12 col-lg-5 bonusResultLeft">
                        <TranslatesContent contentKey="package-name" /> :
                      </div>
                      <div class="col-12 col-lg-7 bonusResultRight">
                        {bonus?.data?.[2]["pkgname"] || "N/A"}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-5 bonusResultLeft">
                        <TranslatesContent contentKey="bonus-amount" /> :
                      </div>
                      <div class="col-12 col-lg-7 bonusResultRight">
                        {reBonus}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-5 bonusResultLeft">
                        <TranslatesContent contentKey="withdrawal-eft-turnover" />{" "}
                        :
                      </div>
                      <div class="col-12 col-lg-7 bonusResultRight">
                        {reTurn}
                      </div>
                    </div>
                    {successMsgRedepo && (
                      <div
                        style={{ marginTop: "30px" }}
                        className="alert alert-success"
                      >
                        {successMsgRedepo}
                      </div>
                      // <div className="alert alert-success">{successMsg}</div>
                    )}

                    {/* bank payment error */}
                    {/* {errmsgRedepo && (
                      <div
                        style={{ marginTop: "30px" }}
                        className="alert alert-danger"
                      >
                        {errmsgRedepo}
                      </div>
                      // <div class="alert alert-danger" role="alert">
                      //   {errmsg}
                      // </div>
                    )} */}
                    {/* <div class="row mt-4">
                      <button
                        type="submit"
                        class="newcashierButton"
                        style={load?{cursor: "not-allowed"}:null}
                        disabled={load}
                        onClick={() => setsubButton("rede")}
                      > */}
                        
                        {/* {load ? (
                          "Processing"
                        ) : (
                          <TranslatesContent contentKey="redeposit-cap" />
                        )} */}
                          {/* <TranslatesContent contentKey="redeposit-cap" /> */}
                      {/* </button> */}
                    {/* </div> */}
                  {/* </div> */}
                {/* </div> */}
              {/* </div> */}
            {/* </div> */}
          {/* </div> */}
        </form>
      </div>
      {/* <WhatsappButton /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    withdrowData: state.WidthdrowData,
    witdrowMethods: state.WithdrowMethods,
    users: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: () => dispatch(UserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdrowal);
