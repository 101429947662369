import axios from "axios";
import { GET_VERSIONS_URL } from "../ApiConstant";

export const versionsLoading = () => {
  return {
    type: "LOADING_VERSIONS",
  };
};

export const versionsSuccess = (data) => {
  return {
    type: "VERSIONS_SUCCESS",
    data: data,
  };
};

export const versionsError = (err) => {
  return {
    type: "VERSIONS_ERROR",
    err: err,
  };
};

// export const getVersions = () => {
//   return (dispatch) => {
//     dispatch(versionsLoading());
//     axios
//       .get(GET_VERSIONS_URL)
//       .then((resp) => {
//         dispatch(versionsSuccess(resp.data));
//         localStorage.setItem("contentVersion", JSON.stringify(resp.data));
//       })
//       .catch((err) => {
//         dispatch(versionsError(err));
//       });
//   };
// };
export const getVersions = () => {

  return (dispatch) => {
    const cachedVersion = localStorage.getItem("contentVersion");
    if (cachedVersion) {
      dispatch(versionsSuccess(JSON.parse(cachedVersion)));
      return;
    }else{
      let jsn = JSON.stringify({"id":5,"url":"win365casino.com/","cdn_url":"siteContent/new-win365casino.com/","en":"4.7","cn":"1.1","hin":"1","telugu":"1","tamil":"1","arabic":"1.2","japanese":"0.2","korean":"0.1","thai":"0.1","modifyDate":"2024-12-04T05:11:12.000Z","domain":"win365casino.com"}) 
      localStorage.setItem("contentVersion", jsn);
      dispatch(versionsSuccess(jsn));
    }

    dispatch(versionsLoading());
    axios
      .get(GET_VERSIONS_URL)
      .then((resp) => {
        dispatch(versionsSuccess(resp.data));
        localStorage.setItem("contentVersion", JSON.stringify(resp.data));
        
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || err.message || "Unknown error occurred";
        dispatch(versionsError(errorMessage));
      });
  };
};

