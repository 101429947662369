import TranslatesContent from "../DataStore/Languages/translatesContent";
var moment = require("moment"); // require


export const DepositMenu = [
    //for remove upi payment
  {
    name: <TranslatesContent contentKey="bank-payment" />,
    //for remove upi payment
    // pill: "profile",
    pill: "home",
    image: "inner-tab-icon-2.png",
  },
  // {
  //   name: <TranslatesContent contentKey="upi-payment" />,
  //   pill: "upi",
  //   image: "inner-tab-icon-1.png",
  // },
  {
    name: "Crypto Payment",
    pill: "crypto",
    image: "inner-tab-icon-5.png",
  },
  {
    name: "QR Payment",
    pill: "QR",
    image: "inner-tab-icon-6.png",
  },
  {
    // name: <TranslatesContent contentKey="credit-card" />,
    name: "Credit Card",
    pill: "messages",
    image: "inner-tab-icon-3.png",
  },
  {
    name: <TranslatesContent contentKey="by-hand" />,
    pill: "settings",
    image: "inner-tab-icon-4.png",
  },
  // {
  //   name: <TranslatesContent contentKey="redeposit" />,
  //   pill: "redeposit",
  //   image: "inner-tab-icon-5.png",
  // },


];

//cashier top menu
export const cashierTopMenu = [
  {
    name: <TranslatesContent contentKey="deposit-simple" />,
    pill: "home",
  },
  {
    name: <TranslatesContent contentKey="withdrawal-sim" />,
    pill: "profile",
  },
  {
    name: <TranslatesContent contentKey="transaction-sim" />,
    pill: "contact",
  },
  {
    name: <TranslatesContent contentKey="fund-transfer-profile" />,
    pill: "transfer",
  },
];

export const fundTransferTypes = {
  1: {
    2: {
      url: "sportBetting/sp_transaction/",
      type: "deposit",
    },
    // 3: {
    //   url: "rummy/credit",
    //   type: "",
    // },
  },
  2: {
    1: {
      url: "sportBetting/sp_transaction/",
      type: "withdrawal",
    },
  },
  // 3: {
  //   1: {
  //     url: "rummy/debit",
  //     type: "",
  //   },
  // },
};

//redeposit sample
export const redeposit = {
  withdraw_method: 1,
  amount: 0,
  witdrowCheck: "off",
  redepositCheck: "on",
  redeposit: 0,
};
//transaction history
export const transactionHistory = {
  startDate: moment().format('YYYY-MM-DD')+` 00:00:00`,
  endDate: moment().format('YYYY-MM-DD')+` 23:59:00`,
  type: "",
  cr_db: "*",
};
export const transactionHistory2 = {
  startDate: "2020-06-14 18:20:02",
  endDate: "2022-06-14 18:20:02",
  type: "",
  cr_db: "*",
};
export const TOAST_CONFIG = { position: "top-center", theme: "dark" };

export const GameHistoryMenu = [
  {
    name: "Baccarat",
    key: "baccarat",
    gameID: "BAC",
    pill: "baccarat",
    image: "tab-icon-hitory-baccarat.png",
  },
  {
    name: "Roulette",
    key: "roullete",
    gameID: "ROU",
    pill: "roullete",
    image: "tab-icon-history-roulette.png",
  },
  {
    name: "Andar Bahar",
    key: "andarBahar",
    gameID: "AND",
    pill: "andarBahar",
    image: "tab-icon-history-andar-bahar.png",
  },
  {
    name: "Dragon Tiger",
    key: "dragonTiger",
    gameID: "DRT",
    pill: "dragonTiger",
    image: "tab-icon-5.png",
  },
  {
    name: "Lucky 7",
    key: "lucky7",
    gameID: "LU7",
    pill: "lucky7",
    image: "tab-icon-history-L7.png",
  },
];
export const BOARD_SELECT_COIN = {
  DRT: {
    t: "tiger_coin",
    d: "dragon_coin",
    tie: "tie_coin",
  },
  LU7: {
    '7u': "l7up_coin",
    '7d': "l7down_coin",
    '7l': "l7_coin",
    'black': "black_coin",
    'red': "red_coin",
    'odd': "odd_coin",
    'even': "even_coin",
  },
  AND: {
    'af': "player_coin_andar1",
    'as': "player_coin_andar2",
    'bf': "player_coin_bahar1",
    'bs': "player_coin_bahar2",
  },
  BAC: {
    'pp': "p_pair_coin",
    'ss': "super_six_coin",
    'bp': "b_pair_coin",
    'p': "player_coin",
    'bs': "tie_coin",
    'bs': "banker_coin",
  },
};
export const CONTACT_DETAILS = {
  withdrow: {
    whatsapp: "+94760979269",
    telegram: "+94760979269"
  },
  deposit: {
    whatsapp: "+94760979279",
    telegram: "+94760979279"
  },
  other: {
    whatsapp: "+94760979279",
    telegram: "+94768557667"
  }
}