import { CREATE_BANK_ACCOUNT_URL } from "../../DataStore/ApiConstant";
import axios from "axios";
import { useState } from "react";
import { useBankAccounts } from "../../services/cashier";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

const BankAccountForm = ({ setHideWithdraw, countryList }) => {
  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [branch, setBranch] = useState("");
  // const [ifsc, setIfsc] = useState("");
  const [accNum, setAccNum] = useState("");
  const [country, setCountry] = useState("");
  const [err, setErr] = useState("");
  const bankAccounts = useBankAccounts();
  const [load, setLoad] = useState(false);

  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [ifsc, setIfsc] = useState('');

  const createBankAccount = () => {
    if (!bankName || !accountName || !branch || !ifsc || !accNum || !country) {
      setErr("All fields are required");
      return;
    }
    setLoad(true);
    const formData = new FormData();
    formData.append("bank_name", bankName);
    formData.append("account_name", accountName);
    formData.append("bank_branch", branch);
    formData.append("ifsc_code", ifsc);
    formData.append("account_number", accNum);
    formData.append("country", country);

    axios
      .post(CREATE_BANK_ACCOUNT_URL, formData)
      .then((resp) => {
        if (resp.data === "done") {
          setSuccessMsg("Bank account created successfully");
          setLoad(false);
          bankAccounts.refetch();
          resetForm();
          setTimeout(() => {
            document.getElementById('collapseaddnewbank').classList.remove('show');
            document.getElementById('bankMainCollapse').classList.add('collapsed');
            document.getElementById('bankMainCollapse').setAttribute('aria-expanded', false);
            setHideWithdraw(false);
            setSuccessMsg("");
          }, 1500);
        } else {
          setErr(resp.data);
          setLoad(false);
        }
      })
      .catch(() => {
        setErrmsg("Bank account creation failed");
        setLoad(false);
      });
  };

  
  const handleChange = (e) => {
    // Convert the input value to uppercase
    const uppercasedValue = e.target.value.toUpperCase();
    // Update the state with the uppercased value
    setIfsc(uppercasedValue);
  };

  const resetForm = () => {
    setBankName("");
    setAccountName("");
    setBranch("");
    setIfsc("");
    setAccNum("");
    setCountry("");
    setErr("");
  };

  return (
    <>
      <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="bank-name" />*
        </label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => setBankName(e.target.value)}
          value={bankName}
        />
      </div>
      <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="account-name" />*
        </label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => setAccountName(e.target.value)}
          value={accountName}
        />
      </div>
      <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="bank-branch" />*
        </label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => setBranch(e.target.value)}
          value={branch}
        />
      </div>
      {/* <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="ifsc-code" />*
        </label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => setIfsc(e.target.value)}
          value={ifsc}
        />
      </div> */}
          <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
            <label>
              <TranslatesContent contentKey="ifsc-code" />*
            </label>
            <input
              className="form-control"
              type="text"
              onChange={handleChange}
              value={ifsc}
            />
          </div>
      <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="account-number" />*
        </label>
        <input
          className="form-control"
          type="number"
          onChange={(e) => setAccNum(e.target.value)}
          value={accNum}
        />
      </div>
      <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          Country *
        </label>
        <select
          className="form-control"
          onChange={(e) => setCountry(e.target.value)}
          value={country}
        >
          <option value="">Select Country</option>
          {Array.isArray(countryList?.data) && countryList.data.map((country) => (
            <option key={country.alpha_code} value={country.alpha_code}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <br />
      {err && (
        <div className="form-message m-2" style={{ color: "red" }}>
          {err}
        </div>
      )}
      <br />
      {successMsg && (
        <div style={{ marginTop: "30px" }} className="alert alert-success">
          {successMsg}
        </div>
      )}
      {errmsg && (
        <div style={{ marginTop: "30px" }} className="alert alert-danger">
          {errmsg}
        </div>
      )}
      <div className="row">
        <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-5 mt-4">
          <button
            disabled={load}
            className="btn btn-primary btn-lg w-100"
            onClick={createBankAccount}
            style={{ width: "220px", cursor: "pointer" }}
          >
            {load ? "PROCESSING" : <TranslatesContent contentKey="add-new-bank" />}
          </button>
        </div>
      </div>
    </>
  );
};

export default BankAccountForm;
