import { useState, useEffect } from "react";
import { validateNumberInput } from "../../utils";
import {
  CRYPTOURL,
  DEOSITBONUSURL,
  DEPOSITURL,
  UPI_PAYMENT
} from "../../DataStore/ApiConstant";
import axios from "axios";
import { BonusCalc } from "./BonusCalc";
import { CalculateTurnover } from "./turnOverCalc";
// import WhatsappButton from "../Cashier/whatsappButton";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { TOAST_CONFIG } from "../../constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import _ from "lodash";
import { getFormData, calculateBonus, calculateTurnover } from "../../utils";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { useWithdrawBonus } from "../../services/cashier";

import { useAtom } from "jotai";
import { bonusStore } from "../../DataStore/atom";
import { useParams } from 'react-router-dom';

let validator = {
  // amount: yup
  //   .number()
  //   .required("Please provide amount.")
  //   .min(1, "Must be greater than 0")
  //   .typeError("Amount must be a number"),
}
const UpiPayment = () => {

  const schema = yup.object().shape(validator);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [BonusStore, setBonusStore] = useAtom(bonusStore);
  const [vpaId, setVpaId] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const bonus = useWithdrawBonus();
  const { type } = useParams();
  let [amount, setAmount] = useState(null);
  const [depositBonus, setDepositBonus] = useState(true);
  const [paymentErr, setPaymentErr] = useState(false);
  const [rdo, setRdo] = useState("upi");
  const [load, setLoad] = useState(false);
  const [reBonus, setReBonus] = useState(0);
  const [reTurn, setReTurn] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);

  const [rawAmount, setRawAmount] = useState(0);
  const [formattedAmount, setFormattedAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState(1);

  let submitHandler = async (upiObj) => {
    setLoad(true);
    // let url = `https://ballysbet.com/api/ipg/online-payment/visa-master-checkout?currency=${selectedCurrency}&amount=${amount}`
    // window.location.href=url;
    // console.log(amount);
    // setLoad(true);
    // let bonusAmt = 2;
    // let url = window.location.pathname;
    // depositBonus == null ? (bonusAmt = 2) : (bonusAmt = 1);
    // let conArr = { ...upiObj, url, bonus: bonusAmt, amount: amount };
    // axios
    //   .post(rdo == "upi" ? DEPOSITURL : CRYPTOURL, getFormData(conArr))
    //   .then((resp) => {
    //     if (resp?.data?.state == false) {
    //       setPaymentErr(resp.data.msg);
    //       // toast.error(resp?.data?.msg, TOAST_CONFIG);
    //       setLoad(false);
    //     }

    //     if (rdo == "upi") {
    //       resp.status && resp.data.data
    //         ? (window.location.href = resp.data.data)
    //         : setPaymentErr(resp.data.msg);
    //       setLoad(false);
    //     } else {
    //       resp.status && resp.data.data
    //         ? (window.location.href = `https://merchant.net-cents.com/widget/payment/currencies?data=${resp.data.data.token}`)
    //         : setPaymentErr(resp.data.msg);
    //       // toast.error(paymentErr, TOAST_CONFIG);
    //       setLoad(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setPaymentErr(err);
    //     // toast.error("err", TOAST_CONFIG);
    //     setLoad(false);
    //   });
    // if (amount === null || amount === 0) {
    //   setPaymentErr("Please enter a valid amount");
    //   return;
    // }
    
    // let formData = new FormData();
    // formData.append("amount", amount);
    
    // axios
    //   .post(UPI_PAYMENT, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then((resp) => {
    //     if (resp.status === 200) {
    //       const redirectUrl = resp.data.data;
    //       window.location.href = redirectUrl;
          
    //       setLoad(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setPaymentErr("An error occurred. Please try again.");
    //     setTimeout(() => {
    //       setPaymentErr("")
    //     }, 5000);
    //     setLoad(false);
    //   });      
    if (rawAmount === null || rawAmount === 0) {
      // alert(amount)
      setPaymentErr("Please enter a valid amount");
      setTimeout(() => {
        setPaymentErr("");
        }, 5000);
        setLoad(false);
      return;
    }
    if (vpaId === null) {
      setPaymentErr("Please enter VPA ID");
      setTimeout(() => {
        setPaymentErr("");
        }, 5000);
        setLoad(false);
      return;
    }
    let bonusstatus = 0;
    depositBonus == null ? (bonusstatus = 0) : (bonusstatus = 1);
    const formData = {"provider": "upi", "fiatAmount": String(rawAmount), "bonus": bonusstatus, "additionalParams": "vpaID="+vpaId}
    
    axios
      .post(UPI_PAYMENT, formData, {
      })
      .then((resp) => {
        if (resp?.data?.success) {
          if(resp?.data?.type == "qr"){
            setQrCodeUrl(resp?.data?.value);
            setIsModalOpen(true)
            setRawAmount(0)
            setVpaId(null)
            setFormattedAmount("")
            setLoad(false);
            return
          }
          else{
            const redirectUrl = resp?.data?.value;
            window.location.href = redirectUrl;
            setRawAmount(0)
            setVpaId(null)
            setFormattedAmount("")
            setLoad(false);
            return
          }
          
          // console.log(resp.data.value);
          
          // const reader = new FileReader();
    
          // Define the onload event for the reader
          // reader.onloadend = () => {
            // The result contains the data as a base64 encoded string
            // const base64Data = reader.result.split(',')[1]; // Remove the data URL prefix
            // const qrCodeUrl = `data:image/png;base64,${base64Data}`;
            

            // openGeneralModal("QRupi");
          // };
        
          // Read the blob as a data URL
          // reader.readAsDataURL(blob);
          setLoad(false);
        }
        else{
          setPaymentErr("An error occurred. Please try again!");
          setVpaId("")
          setFormattedAmount("")
          setRawAmount(0)
          setTimeout(() => {
            setPaymentErr("");
            }, 5000);
          setLoad(false);
        }
      })
      .catch((err) => {
        setPaymentErr("An error occurred. Please try again.");
        setVpaId("")
        setFormattedAmount("")
        setRawAmount(0)
        setTimeout(() => {
          setPaymentErr("");
          }, 5000);
        setLoad(false);
      });     
  };

  const bonusOfferSwitch = async (status) => {

    setSelectedOption((prevOption) => (prevOption === status ? "" : status));
    setBonusStore(status);
    if (status) {
      axios
        .get(DEOSITBONUSURL)
        .then((resp) => {
          setDepositBonus(resp);
        })
        .catch((err) => {});
    } else {
      setDepositBonus(null);
    }
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("en-US", {
      // style: "currency",
      currency: "USD",
    }).format(value);
  
    return formattedValue;
  };

  const calculateBonuses = (reAmount) => {
    const numericValue = reAmount.replace(/[^0-9.]/g, "");
  
    setRawAmount(numericValue)
    // setAmount(reAmount);
    setFormattedAmount(numericValue ? formatCurrency(numericValue) : "");

    setAmount(reAmount);
    if (reAmount != "" && (reAmount < 500 || reAmount > 100000)) {
      setPaymentErr("Please enter amount between min and max limit");
    } else {
      setPaymentErr(false);
    }

    if (bonus.data) {
      let bonusRet = calculateBonus(
        reAmount,
        bonus?.data[2]["maxbonus"] || 0,
        bonus?.data[2]["bonusprecent"] || 0
      );
      setReBonus(bonusRet);
      let turnRet = calculateTurnover(
        reAmount,
        bonus?.data[2]["maxbonus"] || 0,
        bonus?.data[2]["turnover"] || 0,
        bonus?.data[2]["bonusprecent"] || 0
      );
      setReTurn(turnRet);
    }
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const handleFocus = (event) => {
    event.target.select();
  };
  // useEffect(() => {
  //   bonusOfferSwitch(BonusStore);
  // }, [BonusStore]);
  useEffect(() => {
    if (type == "upisuccess") {
      const homeTab = document.getElementById('v-pills-home-tab');
      if (homeTab) homeTab.click()
      setShowSuccessMessage("Your transaction was successful!");
  
      const timer = setTimeout(() => {
        setShowSuccessMessage(null);
      }, 5000);
  
      return () => clearTimeout(timer);
    }
    if(type == "upifailed"){
      const homeTab = document.getElementById('v-pills-home-tab');
      if (homeTab) homeTab.click()
      setShowSuccessMessage("Your transaction failed!");
  
      const timer = setTimeout(() => {
        setShowSuccessMessage(null);
      }, 5000);
  
      return () => clearTimeout(timer);
    }
  }, [type]);
  return (
    <div
      class="tab-pane fade "
      id="v-pills-upi"
      role="tabpanel"
      aria-labelledby="v-pills-upi-tab"
    >
      <div class="row">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div>
            <div class="row">
            <div className="row d-flex justify-content-center my-5">

            <div className="col-md-4 mb-3">
                    <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
                    <TranslatesContent contentKey="deposit-amount" />
                    </label>
                    <div className="input-group">
                      <span
                        className="input-group-text"
                        style={{
                          border: "1px solid #2f2f2f",
                          fontWeight: 600,
                          backgroundColor: "#c7c7c7"
                        }}
                      >
                        LKR
                      </span>
                        <input
                            type="text"
                            value={formattedAmount}
                            style={{textAlign:"right"}}
                            className={`form-control ${
                              _.isEmpty(errors)
                                ? ""
                                : errors?.amount
                                ? "is-invalid"
                                : "is-valid"
                            }`}
                            placeholder="0"
                            onFocus={handleFocus}
                            onInput={(e) => {
                              calculateBonuses(e.target.value);
                            }}
                            // {...register("amount")}
                          />
                      <div className="col-12">
                        <div className="form-text text-center">
                        <TranslatesContent contentKey="min-max-limit" />: LKR
                        500 / LKR 5000
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
                      VPA ID{" "}
                    </label>
                    <div className="input-group">
                    <input
                        type="text"
                        value={vpaId}
                        style={{textAlign:"right"}}
                        onChange={(e) =>
                          setVpaId(e.target.value)
                        }
                        
                        className={`form-control`}
                        placeholder="VPA ID"
                        onFocus={handleFocus}
                        // {...register("amount")}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center">
                  <div className="col-md-3 mb-3">
                      <div className="row">
                        <div className="col-12" style={{ textAlign: "center" }}>
                          <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
                            Bonus Activation
                          </label>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div className="px-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                style={{padding:"0"}}
                                type="radio"
                                defaultValue=""
                                id="flexCheckDefault"
                                checked={selectedOption === 1}
                                onChange={() => bonusOfferSwitch(1)}
                              />
                              <label
                                className="form-check-label mt-1 ms-2"
                                htmlFor="flexCheckDefault"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                          <div className="px-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                style={{padding:"0"}}
                                type="radio"
                                defaultValue=""
                                id="flexCheckDefault"
                                checked={selectedOption === 0}
                                onChange={() => bonusOfferSwitch(0)}
                              />
                              <label
                                className="form-check-label mt-1 ms-2"
                                htmlFor="flexCheckChecked"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
                      Bonus
                    </label> */}
                    {/* <div className="fform-check form-switch form-switch-lg">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        defaultChecked=""
                        checked={BonusStore}
                        onChange={(e) => {
                          bonusOfferSwitch(e.target.checked);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                        style={{ marginLeft: 15, marginTop: 10 }}
                      >
                        Add Bonus Offer
                      </label>
                    </div> */}
                  </div>
                  <div className="row d-flex justify-content-center">
                <div className="col-md-6 mb-3">
                    {errors?.amount?.message ? (
                      <div className="invalid-feedback">
                        {errors?.amount?.message}
                      </div>
                    ) : null}
                    {paymentErr && (
                        <div class="alert alert-danger" role="alert">
                          {paymentErr}
                        </div>
                      )}
                      <div>
                          {showSuccessMessage && (
                            <div className={showSuccessMessage.includes("failed!") ? "success-message-error" : "success-message"}>
                              {showSuccessMessage}
                            </div>
                          )}
                          {/* The rest of your component code */}
                        </div>
                  </div>
                </div>
                      <div class="row d-flex justify-content-center">
                            <div class="col-md-4 mb-3">
                              <button
                                  type="submit"
                                  class="btn btn-primary btn-lg w-100"
                                  disabled={load}
                                >
                                  {load ? (
                                    "Processing..."
                                  ) : (
                                    "CONFIRM"
                                  )}
                              </button>
                            </div>
                        </div>
                </div>





              <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 offset-0">
                    <div class="row">
                    <div className="col-12 col-lg-7 col-md-7 col-sm-12 col-xl-7 mt-4">
                        {/* <label
                          for="exampleFormControlInput1"
                          className="cashierNewTitel"
                        >
                          <TranslatesContent contentKey="deposit-amount" />
                        </label> */}
                        {/* <input
                          type="number"
                          value={amount}
                          onChange={(e) => {
                            calculateBonuses(e.target.value);
                          }}
                          className={`form-control ${
                            _.isEmpty(errors)
                              ? ""
                              : errors?.amount
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          placeholder=""
                          onFocus={handleFocus}
                          // {...register("amount")}
                        /> */}
                        {/* {errors?.amount?.message ? (
                          <div className="invalid-feedback">
                            {errors?.amount?.message}
                          </div>
                        ) : null}
                        <div
                          id="emailHelp"
                          className="form-text text-start"
                          style={{ color: "#818181" }}
                        >
                          <TranslatesContent contentKey="min-max-limit" />: INR
                          500 / INR 5000
                        </div> */}
                      {/* </div> */}
                      {/* <label
                        for="exampleFormControlInput1"
                        class="cashierNewTitel"
                      >
                        <TranslatesContent contentKey="select-gateway" />
                      </label> */}
                      {/* <div class="col-12 col-lg-4 col-md-4 col-sm-6">
                        <div class="radio">
                          <input
                            id="radio-1"
                            value={"upi"}
                            onClick={(e) => setRdo("upi")}
                            name="radio"
                            type="radio"
                            checked={rdo == "upi" ? true : false}
                          />
                          <label for="radio-1" class="radio-label">
                            <TranslatesContent contentKey="gateway-a" />
                          </label>
                        </div>
                      </div> */}
                      {/* <div class="col-12 col-lg-4 col-md-4 col-sm-6">
                        <div
                          class="radio"
                          style={{
                            opacity: "0.1",
                          }}
                        >
                          <input
                            disabled
                            id="radio-2"
                            value={crypto}
                            onClick={(e) => setRdo("crypto")}
                            name="radio"
                            type="radio"
                            checked={rdo == "crypto" ? true : false}
                          />
                          <label for="radio-2" class="radio-label">
                            <TranslatesContent contentKey="gateway-b" />
                          </label>
                        </div>
                      </div> */}
                          {/* <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mt-4">
                              <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
                                <TranslatesContent contentKey="deposit-amount" />
                              </label>
                              <div className="d-flex">
                                <select
                                  className="form-select me-2"
                                  value={selectedCurrency}
                                  onChange={handleCurrencyChange}
                                  style={{width:'100px'}}
                                >
                                  <option value="USD">USD</option>
                                  <option value="LKR">LKR</option>
                                </select>
                                <input
                                  onInput={(e) => validateNumberInput(e.target)}
                                  type="number"
                                  value={amount}
                                  onChange={(e) => {
                                    calculateBonuses(e.target.value);
                                  }}
                                  className={`form-control ${_.isEmpty(errors) ? '' : errors?.amount ? 'is-invalid' : 'is-valid'}`}
                                  placeholder=""
                                  onFocus={handleFocus}
                                />
                              </div>
                              {errors?.amount?.message && (
                                <div className="invalid-feedback">
                                  {errors.amount.message}
                                </div>
                              )}
                              <div id="emailHelp" className="form-text text-start" style={{ color: '#818181',marginRight:'10px' }}>
                                <TranslatesContent contentKey="min-max-limit" />: 50 / 5,000
                              </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <BonusOffer name={"deposit-bonus"} /> */}
              <div class="col-lg-6 offset-xl-1 offset-lg-2 offset-md-2 offset-sm-0 offset-0 mt-2">
                <div class="row">
                  <div class="col-12">
                    <div class="row my-3  my-xl-4 my-lg-4 my-md-4 my-sm-4">
                      <div class="col-12">
                        <label
                          for="exampleFormControlInput1"
                          class="cashierNewTitel"
                        >
                          {/* <TranslatesContent contentKey="bonus" /> */}
                        </label>
                      </div>
                      {/* <div class="col-12">
                        <input
                          type="checkbox"
                          class="my-checkbox"
                          data-val="1"
                          checked={BonusStore}
                          onChange={(e) => {
                            bonusOfferSwitch(e.target.checked);
                          }}
                        />
                        <TranslatesContent contentKey="activate" />
                      </div> */}
                    </div>
                    {/* {BonusStore ? (
                      <div>
                        <div class="row">
                          <div class="col-12 col-lg-5 bonusResultLeft">
                            <TranslatesContent contentKey="bonus-amount" />:
                          </div>
                          <div class="col-12 col-lg-7 bonusResultRight">
                            {reBonus}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-lg-5 bonusResultLeft">
                            <TranslatesContent contentKey="withdrawal-turnover" />
                            :
                          </div>
                          <div class="col-12 col-lg-7 bonusResultRight">
                            {reTurn}
                          </div>
                        </div>
                      </div>
                    ) : null} */}
                    {/* {paymentErr && (
                      // <div
                      //   style={{ marginTop: "30px" }}
                      //   className="alert alert-danger"
                      // >
                      //   {paymentErr}
                      // </div>
                      
                      <div class="alert alert-danger" role="alert">
                        {paymentErr}
                      </div>
                    )} */}
                    {/* <div> */}
                        {/* {showSuccessMessage && (
                          <div className={showSuccessMessage.includes("failed!") ? "success-message-error" : "success-message"}>
                            {showSuccessMessage}
                          </div>
                        )} */}
                        {/* The rest of your component code */}
                      </div>
                    <div class="row mt-4">
                      {/* <div class="col-6 col-lg-4 col-md-4 col-sm-4">
                        <button
                          type="submit"
                          class="newcashierButton"
                          disabled={load}
                        >
                          {load ? (
                            "Processing..."
                          ) : (
                            <TranslatesContent contentKey="submit" />
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* whatsapp button */}
        {/* <WhatsappButton /> */}
      </div>
    </div>
  );
};

export default UpiPayment;
